import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar Bootstrap CSS
import 'aos/dist/aos.css'; // Importar los estilos de AOS
import AOS from 'aos';
import '../styles/EventosEspecialesSection.css'; // Asegúrate de crear este archivo para los estilos


const publicUrl = process.env.PUBLIC_URL;

const EventosEspeciales = () => {
  const [activeTab, setActiveTab] = useState('Graduations 2024');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out' });
  }, []);

  return (
    <div className="eventos-especiales">
      <div className="header-container" data-aos="zoom-in">
        <img
          src={`${publicUrl}/media/encabezados/festejos.png`}
          alt="Speakfest 2024"
          className="header-image"
        />
      </div>
      <div className="tabs">
        <button onClick={() => handleTabClick('Graduations 2024')} className={activeTab === 'Graduations 2024' ? 'active' : ''}>Graduación 2024</button>
        <button onClick={() => handleTabClick('Saint Patrick\'s')} className={activeTab === 'Saint Patrick\'s' ? 'active' : ''}>Saint Patrick's</button>
        <button onClick={() => handleTabClick('Valentine\'s day')} className={activeTab === 'Valentine\'s day' ? 'active' : ''}>Valentine's day</button>
        <button onClick={() => handleTabClick('Halloween')} className={activeTab === 'Halloween' ? 'active' : ''}>Halloween</button>
        <button onClick={() => handleTabClick('Independence')} className={activeTab === 'Independence' ? 'active' : ''}>Independence</button>
        <button onClick={() => handleTabClick('Speakfest')} className={activeTab === 'Speakfest' ? 'active' : ''}>Speakfest</button>
      </div>
      <div className="carousel-container-festejos">
        <div className={`carousel-wrapper-festejos ${activeTab === 'Graduations 2024' ? 'active' : ''}`}>
          <Carousel className="fixed-carousel-festejos">
            {Array.from({ length: 33 }, (_, index) => (
              <Carousel.Item key={index} className="carousel-item-festejos">
                <img
                  className="d-block w-100"
                  src={`${process.env.PUBLIC_URL}/media/events/Graduations2024/${index + 1}.png`}
                  alt={`graduations2024 ${index + 1}`}
                />
              </Carousel.Item>
            ))} 
          </Carousel>
        </div>

        <div className={`carousel-wrapper-festejos ${activeTab === 'Saint Patrick\'s' ? 'active' : ''}`}>
          <Carousel className="fixed-carousel-festejos">
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/stPatrick/1.jpeg`}
                alt="Saint Patrick's 1"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/stPatrick/2.jpeg`}
                alt="Saint Patrick's 2"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/stPatrick/3.jpeg`}
                alt="Saint Patrick's 3"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/stPatrick/4.jpeg`}
                alt="Saint Patrick's 4"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/stPatrick/5.jpeg`}
                alt="Saint Patrick's 5"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className={`carousel-wrapper-festejos ${activeTab === 'Valentine\'s day' ? 'active' : ''}`}>
          <Carousel className="fixed-carousel-festejos">
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/valentine/1.jpeg`}
                alt="Valentine's day 1"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/valentine/2.jpeg`}
                alt="Valentine's day 2"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/valentine/3.jpeg`}
                alt="Valentine's day 3"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/valentine/4.jpeg`}
                alt="Valentine's day 4"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/valentine/5.jpeg`}
                alt="Valentine's day 5"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className={`carousel-wrapper-festejos ${activeTab === 'Halloween' ? 'active' : ''}`}>
          <Carousel className="fixed-carousel-festejos">
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/halloween/slide1.jpeg`}
                alt="Halloween 1"
              />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/halloween/slide2.jpeg`}
                alt="Halloween 2"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/halloween/slide3.jpeg`}
                alt="Halloween 3"
              />
            </Carousel.Item> */}
          </Carousel>
        </div>
        <div className={`carousel-wrapper-festejos ${activeTab === 'Independence' ? 'active' : ''}`}>
          <Carousel className="fixed-carousel-festejos">
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/independence/slide1.jpeg`}
                alt="Independence 1"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className={`carousel-wrapper-festejos ${activeTab === 'Speakfest' ? 'active' : ''}`}>
          <Carousel className="fixed-carousel-festejos">
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/speakfest/1.png`}
                alt="Speakfest 1"
              />
            </Carousel.Item>
            {/* <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/speakfest/2.png`}
                alt="Speakfest 2"
              />
            </Carousel.Item> */}
            <Carousel.Item className="carousel-item-festejos">
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/media/events/speakfest/3.jpg`}
                alt="Speakfest 3"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default EventosEspeciales;
