import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar Bootstrap CSS
import '../styles/ImageSlider.css';

const ImageSlider = () => {
  const message = `Hola, me interesa saber más sobre Speakland`;
  const whatsappUrl = `https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent(message)}`;

  return (
    <div className="slider-container">
      <div className="carousel-text">
        <p className="small-text">LEARN FROM THE BEST</p>
        <h1 className="large-text">BIENVENIDO A SPEAKLAND</h1>
        <a
          href={whatsappUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary"
        >
          Solicita más información aquí
        </a>
      </div>
      <Carousel controls indicators interval={2000} className="carousel">
      <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/media/carrousel/graduacion2024.png`}
            alt="Slide 1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/media/carrousel/main.jpeg`}
            alt="Slide 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/media/carrousel/main2.jpeg`}
            alt="Slide 3"
          />
        </Carousel.Item>
        {/* Agrega más imágenes si es necesario */}
      </Carousel>
    </div>
  );
};

export default ImageSlider;
